<template>
    <div style="display: flex;flex-direction: column;height: 100%">
        <div class="login-container">
            <div class="logo-box">
                <router-link to="/index">  <img src="../assets/images/logo-fff.png" height="30" width="140" class="logo" style="vertical-align: bottom;"/>
                    <nav class="logo-nav">用心服务每位客户</nav></router-link>
            </div>
            <p class="welcome-text">欢迎登录</p>
            <div class="container-box" v-loading="loading">
                <h1><img src="../assets/images/logo.png" height="43" width="187" class="logo"/></h1>
                <el-form style="margin-top: 4rem" size="medium">
                    <el-form-item required style="margin-bottom: 36px">
                        <el-input v-model="user.userAccount" required placeholder="用户名" ></el-input>
                    </el-form-item>
                    <el-form-item required>
                        <el-input v-model="user.userPassword" type="password" required placeholder="密码" ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox style="margin:0 0 20px 0;">
                            记住账号密码
                        </el-checkbox>
                        <span style="float: right;"><router-link to="/Regist" style="font-size: 14px;color: #999">注册</router-link></span>
                    </el-form-item>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" style="width: 100%" @click="login" round>提交</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <footer>
                <p class="f-p">版权所有 2008-2022 ICP备88888888 Powered by Meinfo 5.3.11 ©2008-2022</p>
            </footer>
        </div>
    </div>
</template>

<script>
    import Header from "@/components/common/Header";
    import Buttom from "@/components/common/Buttom";

    export default {
        name: "Login",
        data: function () {
            return {
                user: {
                    userAccount: '',
                    userPassword: ''
                },
                loading: false

            }
        },
        components: {
            Header,
        },
        methods: {
            login() {
                // this.$router.push("/MyCenterInfo")
                // return;
                if (!this.user.userAccount) {
                    this.$message.error("请输入用户名")
                    return
                }
                if (!this.user.userPassword) {
                    this.$message.error("请输入密码")
                    return
                }
                this.loading = true
                this.HTTP.post("/user/login", this.user).then(res => {
                    if (res.data.success) {
                        this.$message.success("登录成功")
                        sessionStorage.setItem("userinfo", JSON.stringify(res.data.data))
                        sessionStorage.setItem('token', res.data.data.token);
                        this.$store.commit('setToken', res.data.data.token);
                        this.$router.push("/index")
                    } else {
                        this.$message.error(res.data.message)
                    }
                    this.loading = false
                }).catch(err => {
                    this.$message.error("系统开小差了!")
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .logo-box{
        position absolute;
        top 3%;
        left 20%
    }
    .logo-nav{
        display inline-block;
        border-left 1px solid #fff;
        margin-left 20px;
        padding-left 20px;
        height 30px;
        line-height 30px;
        color #fff;
        font-size 20px;
        letter-spacing: 2px;
    }
    .f-p{
        font-size: 12px;
        color: #cecece;
        text-align: center;
        margin-top: 11em;
    }

    .welcome-text {
        text-align center;
        font-size 42px;
        color #fff;
        letter-spacing 5px;
    }
    >>> .el-button{
        background-color #1a4fcd
    }
    >>> .el-button:hover{
        background-color #66b1ff
    }
    .login-container
        width 100%
        box-sizing border-box
        padding-top 15rem
        background url("../assets/images/login-back.jpg")
        background-size 100% 100%
        flex 1

        .container-box
            background-color white
            margin 0 auto
            width 400px
            height 470px
            border 1px solid lightgrey
            border-radius 0.5rem
            padding 7rem 6rem 0 6rem

            h1
                color: #2567ab;
                font-size 2.5rem
                text-align center
                margin 2rem 0
                margin-top 0
</style>
